// import React, { useState, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import { useNavigate } from 'react-router-dom';
// import { Menu as MenuIcon } from '@mui/icons-material';
// import { Menu, MenuItem, IconButton } from '@mui/material';

// const Navbar = () => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login
//   const navigate = useNavigate();
//   const open = Boolean(anchorEl);

//   useEffect(() => {
//     // Check if user is logged in by checking localStorage
//     const token = localStorage.getItem('token');
//     setIsLoggedIn(!!token); // If token exists, set logged in to true
//   }, []);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const handleNavigation = (path) => {
//     navigate(path);
//     handleClose();
//   };

//   const handleLoginLogout = () => {
//     if (isLoggedIn) {
//       // Perform logout logic, remove token from localStorage
//       localStorage.removeItem('token');
//       setIsLoggedIn(false);
//       navigate('/login');
//     } else {
//       // Navigate to login page
//       navigate('/login');
//     }
//   };

//   return (
//     <header className="bg-gradient-to-r from-gray-100 via-white to-gray-100 shadow-md w-full">
//       <div className="max-w-7xl mx-auto flex items-center justify-between px-4 py-6 md:py-8">
//         {/* Brand Logo */}
//         <div className="relative flex items-center">
//           <motion.h1
//             className="text-3xl md:text-5xl font-extrabold tracking-wide"
//             initial={{ opacity: 0, scale: 0.9 }}
//             animate={{ opacity: 1, scale: 1 }}
//             transition={{ duration: 0.7, ease: 'easeOut' }}
//           >
//             <motion.span
//               className="text-black"
//               initial={{ letterSpacing: '0.1em' }}
//               animate={{ letterSpacing: 'normal' }}
//               transition={{ delay: 0.5, duration: 1 }}
//             >
//               Hire
//             </motion.span>
//             <motion.span
//               className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2"
//               initial={{ backgroundPosition: '200% center' }}
//               animate={{ backgroundPosition: '0% center' }}
//               transition={{ duration: 3, ease: 'linear' }}
//             >
//               Hack
//             </motion.span>
//           </motion.h1>
//         </div>

//         {/* Flex container to handle both the menu and login/logout button */}
//         <div className="flex items-center space-x-4">
//           {/* Mobile Menu Icon */}
//           <div className="flex md:hidden">
//             <IconButton
//               aria-controls="menu"
//               aria-haspopup="true"
//               onClick={handleClick}
//               className="text-gray-600 hover:text-blue-600 transition-colors text-3xl"
//             >
//               <MenuIcon />
//             </IconButton>
//           </div>

//           {/* Login/Logout Button for Larger Screens */}
//           <motion.button
//             className="bg-blue-600 text-white py-2 px-4 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300 md:flex hidden"
//             whileHover={{ scale: 1.1 }}
//             whileTap={{ scale: 0.95 }}
//             onClick={handleLoginLogout} // Handle login or logout
//           >
//             {isLoggedIn ? 'Logout' : 'Login'}
//           </motion.button>

//           {/* Dropdown Menu for All Screens */}
//           <Menu
//             id="menu"
//             anchorEl={anchorEl}
//             open={open}
//             onClose={handleClose}
//             PaperProps={{
//               sx: {
//                 minWidth: 180,
//                 backgroundColor: 'white',
//                 borderRadius: '8px',
//                 boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
//               },
//             }}
//             anchorOrigin={{
//               vertical: 'top',
//               horizontal: 'right',
//             }}
//             transformOrigin={{
//               vertical: 'top',
//               horizontal: 'right',
//             }}
//           >
//             <MenuItem onClick={() => handleNavigation('/faq')}>FAQ</MenuItem>
//             <MenuItem onClick={() => handleNavigation('/help')}>Help</MenuItem>
//             <MenuItem onClick={() => handleNavigation('/account')}>Account</MenuItem>
//             <MenuItem onClick={() => handleNavigation('/pricing')}>See Pricing</MenuItem>
//           </Menu>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Navbar;

// import * as React from "react";
// import PropTypes from "prop-types";
// import { useNavigate } from "react-router-dom";
// import AppBar from "@mui/material/AppBar";
// import Box from "@mui/material/Box";
// import CssBaseline from "@mui/material/CssBaseline";
// import Divider from "@mui/material/Divider";
// import { motion } from "framer-motion";
// import Drawer from "@mui/material/Drawer";
// import IconButton from "@mui/material/IconButton";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemText from "@mui/material/ListItemText";
// import MenuIcon from "@mui/icons-material/Menu";
// import Toolbar from "@mui/material/Toolbar";
// import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import LogoutIcon from "@mui/icons-material/Logout";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import { useSelector, useDispatch } from "react-redux";
// import { logout } from "../../redux/authSlice"; // Adjust the import path to your actual authSlice
// import Logo from "../../Images/LOGO.png";
// const drawerWidth = 240;

// // Define common navItems

// // Define loggedInNavItems
// const loggedInNavItems = [
//   { label: "Dashboard", route: "/dashboard-layout/analytics" },
// ];

// function Navbar(props) {
//   const user = useSelector((state) => state.auth.user);
//   const navItems = [
//     { label: "Home", route: "/" },
//     { label: "About", route: "/about" },
//     { label: "Contact", route: "/contact" },
//     ...(user
//       ? [{ label: "Dashboard", route: "/dashboard-layout/analytics" }]
//       : []),
//     { label: "Pricing", route: "/pricing" },
//     { label: "How it Works", route: "/how-it-works" },
//     { label: "Blog", route: "/blog" },
//     { label: "Testimonial", route: "/testimonial" },
//   ];
//   const { window } = props;
//   const [mobileOpen, setMobileOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();

//   console.log(user);
//   const handleDrawerToggle = () => {
//     setMobileOpen((prevState) => !prevState);
//   };

//   const handleNavClick = (path) => {
//     navigate(path);
//   };

//   const handleLogout = () => {
//     dispatch(logout());
//     navigate("/login");
//   };

//   // Combine navItems with loggedInNavItems if the user is logged in
//   const fullNavItems = user ? [...navItems, ...loggedInNavItems] : navItems;

//   // Dropdown controls
//   const handleMenuOpen = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const drawer = (
//     <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
//       <Typography variant="h6" sx={{ my: 2 }}>
//         <a href="/" className="inline-flex items-center">
//           <img src={Logo} alt="Comany Logo" className="mx-4" width={30}></img>
//           <motion.span
//             className="text-black "
//             initial={{ letterSpacing: "0.1em" }}
//             animate={{ letterSpacing: "normal" }}
//             transition={{ delay: 0.5, duration: 1 }}
//           >
//             Hire
//           </motion.span>
//           <motion.span
//             className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2"
//             initial={{ backgroundPosition: "200% center" }}
//             animate={{ backgroundPosition: "0% center" }}
//             transition={{ duration: 3, ease: "linear" }}
//           >
//             Hack
//           </motion.span>
//         </a>
//       </Typography>
//       <Divider />
//       <List>
//         {fullNavItems.map((item) => (
//           <ListItem key={item.label} disablePadding>
//             <ListItemButton sx={{ textAlign: "center" }}>
//               <ListItemText
//                 primary={item.label}
//                 onClick={() => handleNavClick(item.route)}
//               />
//             </ListItemButton>
//           </ListItem>
//         ))}
//       </List>
//     </Box>
//   );

//   const container =
//     window !== undefined ? () => window().document.body : undefined;

//   return (
//     <Box sx={{ display: "flex" }}>
//       <CssBaseline />
//       <AppBar
//         component="nav"
//         sx={{ backgroundColor: "white", color: "black", height: "60px" }}
//       >
//         <Toolbar>
//           <IconButton
//             color="inherit"
//             aria-label="open drawer"
//             edge="start"
//             onClick={handleDrawerToggle}
//             sx={{ mr: 2, display: { sm: "none" } }}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Box
//             sx={{
//               flexGrow: 1,
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <Typography
//               variant="h6"
//               component="div"
//               sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
//             >
//               <div className="relative flex items-center">
//                 <motion.h1
//                   className="text-2xl md:text-4xl font-extrabold tracking-wide"
//                   initial={{ opacity: 0, scale: 0.9 }}
//                   animate={{ opacity: 1, scale: 1 }}
//                   transition={{ duration: 0.7, ease: "easeOut" }}
//                 >
//                   <a href="/" className="inline-flex items-center">
//                     <img
//                       src={Logo}
//                       alt="Comany Logo"
//                       className="mx-4"
//                       width={45}
//                     ></img>
//                     <motion.span
//                       className="text-black"
//                       initial={{ letterSpacing: "0.1em" }}
//                       animate={{ letterSpacing: "normal" }}
//                       transition={{ delay: 0.5, duration: 1 }}
//                     >
//                       Hire
//                     </motion.span>
//                     <motion.span
//                       className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 "
//                       initial={{ backgroundPosition: "200% center" }}
//                       animate={{ backgroundPosition: "0% center" }}
//                       transition={{ duration: 3, ease: "linear" }}
//                     >
//                       Hack
//                     </motion.span>
//                   </a>
//                 </motion.h1>
//               </div>
//             </Typography>
//             <Box
//               sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}
//             >
//               {fullNavItems.slice(0, 4).map((item) => (
//                 <Button
//                   key={item.label}
//                   sx={{ color: "black", mx: 1 }}
//                   onClick={() => handleNavClick(item.route)}
//                 >
//                   {item.label}
//                 </Button>
//               ))}
//               <Button
//                 sx={{ color: "black", mx: 1 }}
//                 onMouseEnter={handleMenuOpen}
//                 onClick={handleMenuOpen}
//               >
//                 More
//               </Button>
//               <Menu
//                 anchorEl={anchorEl}
//                 open={Boolean(anchorEl)}
//                 onClose={handleMenuClose}
//                 MenuListProps={{
//                   onMouseLeave: handleMenuClose,
//                 }}
//               >
//                 {navItems
//                   .filter((item) =>
//                     ["How it Works", "Blog", "Testimonial"].includes(item.label)
//                   )
//                   .map((item) => (
//                     <MenuItem
//                       key={item.label}
//                       onClick={() => {
//                         handleNavClick(item.route);
//                         handleMenuClose();
//                       }}
//                     >
//                       {item.label}
//                     </MenuItem>
//                   ))}
//               </Menu>
//             </Box>
//           </Box>
//           <Box sx={{ display: "flex", alignItems: "center" }}>
//             {user ? (
//               <>
//                 <Typography
//                   variant="body1"
//                   sx={{ display: "inline-block", marginRight: 2 }}
//                 >
//                   {` ${user.name}`}
//                 </Typography>
//                 <Button
//                   startIcon={<LogoutIcon />}
//                   onClick={handleLogout}
//                 ></Button>
//               </>
//             ) : (
//               <Button
//                 className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-6 w-24 font-bold btn-alt"
//                 style={{
//                   borderRadius: "32px",
//                   color: "white",
//                 }}
//                 initial={{ backgroundPosition: "200% center" }}
//                 animate={{ backgroundPosition: "0% center" }}
//                 transition={{ duration: 0.2, ease: "linear" }}
//                 whileHover={{ scale: 1.1 }}
//                 whileTap={{ scale: 0.95 }}
//                 onClick={() => handleNavClick("/login")}
//               >
//                 Login
//               </Button>
//             )}
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <nav>
//         <Drawer
//           container={container}
//           variant="temporary"
//           open={mobileOpen}
//           onClose={handleDrawerToggle}
//           ModalProps={{
//             keepMounted: true,
//           }}
//           sx={{
//             display: { xs: "block", sm: "none" },
//             "& .MuiDrawer-paper": {
//               boxSizing: "border-box",
//               width: drawerWidth,
//             },
//           }}
//         >
//           {drawer}
//         </Drawer>
//       </nav>
//     </Box>
//   );
// }

// Navbar.propTypes = {
//   window: PropTypes.func,
// };

// export default Navbar;

import * as React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/authSlice";
import { motion } from "framer-motion";
import Logo from "../../Images/LOGO.png";

const drawerWidth = 240;

const navItems = [
  { label: "Home", route: "/" },
  { label: "About", route: "/about" },
  { label: "Pricing", route: "/pricing" },
  { label: "Contact", route: "/contact" },
];

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleNavClick = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
    handleMenuClose();
  };

  const moreItems = [
    { label: "How it Works", route: "/how-it-works" },
    { label: "Blog", route: "/blog" },
    { label: "Testimonial", route: "/testimonial" },
  ];

  const fullMoreItems = user
    ? [...moreItems, { label: "Logout", onClick: handleLogout }]
    : moreItems;

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <a href="/" className="inline-flex items-center">
          <img src={Logo} alt="Comany Logo" className="mx-4" width={30}></img>
          <motion.span
            className="text-black "
            initial={{ letterSpacing: "0.1em" }}
            animate={{ letterSpacing: "normal" }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            Hire
          </motion.span>
          <motion.span
            className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2"
            initial={{ backgroundPosition: "200% center" }}
            animate={{ backgroundPosition: "0% center" }}
            transition={{ duration: 3, ease: "linear" }}
          >
            Hack
          </motion.span>
        </a>
      </Typography>
      <List>
        {[...navItems, ...fullMoreItems].map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              sx={{ textAlign: "center" }}
              onClick={item.onClick || (() => handleNavClick(item.route))}
            >
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{ backgroundColor: "white", color: "black", height: "60px" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <div className="relative flex items-center">
              <motion.h1
                className="text-2xl md:text-4xl font-extrabold tracking-wide"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.7, ease: "easeOut" }}
              >
                <a href="/" className="inline-flex items-center">
                  <img
                    src={Logo}
                    alt="Comany Logo"
                    className="mx-4"
                    width={45}
                  ></img>
                  <motion.span
                    className="text-black"
                    initial={{ letterSpacing: "0.1em" }}
                    animate={{ letterSpacing: "normal" }}
                    transition={{ delay: 0.5, duration: 1 }}
                  >
                    Hire
                  </motion.span>
                  <motion.span
                    className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 "
                    initial={{ backgroundPosition: "200% center" }}
                    animate={{ backgroundPosition: "0% center" }}
                    transition={{ duration: 3, ease: "linear" }}
                  >
                    Hack
                  </motion.span>
                </a>
              </motion.h1>
            </div>
          </Typography>
          <Box
            sx={{ display: { xs: "none", sm: "flex" }, alignItems: "center" }}
          >
            {navItems.map((item) => (
              <Button
                key={item.label}
                sx={{ color: "black", mx: 1 }}
                onClick={() => handleNavClick(item.route)}
              >
                {item.label}
              </Button>
            ))}
            <Button
              sx={{ color: "black", mx: 1 }}
              onMouseEnter={handleMenuOpen}
              onClick={handleMenuOpen}
            >
              More
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              MenuListProps={{
                onMouseLeave: handleMenuClose,
              }}
            >
              {fullMoreItems.map((item) => (
                <MenuItem
                  key={item.label}
                  onClick={
                    item.onClick ||
                    (() => {
                      handleNavClick(item.route);
                      handleMenuClose();
                    })
                  }
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
            {user ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  borderRadius: "32px",
                  width: "140px",
                  p: 1,
                  backgroundImage:
                    "linear-gradient(to right, #3b82f6, #a855f7, #ec4899)",
                }}
                onClick={() => handleNavClick("/dashboard-layout/analytics")}
              >
                Dashboard
              </Button>
            ) : (
              <Button
                sx={{
                  color: "white",
                  width: "100px",
                  background:
                    "linear-gradient(to right, #3b82f6, #8b5cf6, #ec4899)",
                  borderRadius: "32px",
                  "&:hover": {
                    opacity: 0.9,
                  },
                }}
                onClick={() => handleNavClick("/login")}
              >
                Login
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
