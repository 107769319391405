// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import {
//   Box,
//   Button,
//   Typography,
//   TextField,
//   CircularProgress,
//   Modal,
//   Backdrop,
//   Fade,
// } from "@mui/material";
// import { motion } from "framer-motion";
// import { useSelector, useDispatch } from "react-redux";
// import BASE_URL from "../../../API/BaseUrl";
// const rounds = [
//   { roundName: "Technical Interview", difficulty: "Moderate" },
//   { roundName: "Technical Interview Advance", difficulty: "Hard" },
//   { roundName: "Managerial Round", difficulty: "Moderate" },
// ];

// const InterviewRound = () => {
//   const [roundIndex, setRoundIndex] = useState(0);
//   const [questions, setQuestions] = useState([]);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
//   const [answer, setAnswer] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [modalOpen, setModalOpen] = useState(true);
//   const [breakTimer, setBreakTimer] = useState(60); // 1 minute break timer
//   const [isBreak, setIsBreak] = useState(false);
//   const [isMicOn, setIsMicOn] = useState(false);
//   const navigate = useNavigate();
//   const user = useSelector((state) => state.auth.user);
//   let userId;

//   if (user.id === undefined) {
//     userId = user._id;
//   } else {
//     userId = user.id;
//   }

//   const company = localStorage.getItem("company");
//   const topic = localStorage.getItem("topic");
//   // const userId = "66f981c834993d956d8e0c43"; // Replace with actual user ID logic

//   useEffect(() => {
//     const fetchQuestions = async () => {
//       setIsLoading(true);
//       try {
//         const round = rounds[roundIndex];
//         const response = await axios.post(
//           `${BASE_URL}/api/interview/generate-questions/${userId}`,
//           {
//             topic,
//             companyName: company,
//             interviewType: round.roundName,
//             numQuestions: 5, // Adjust the number of questions
//           }
//         );
//         setQuestions(response.data.questions);
//         setCurrentQuestionIndex(0);
//       } catch (error) {
//         console.error("Error fetching questions:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchQuestions();

//     // Keep fullscreen mode
//     // document.documentElement.requestFullscreen().catch((err) => {
//     //   console.error("Error enabling full screen:", err);
//     // });

//     // Timer for the break
//     let timerId;
//     if (isBreak) {
//       timerId = setInterval(() => {
//         setBreakTimer((prev) => {
//           if (prev === 1) {
//             clearInterval(timerId);
//             setIsBreak(false);
//             return 60; // Reset timer for next break
//           }
//           return prev - 1;
//         });
//       }, 1000);
//     }

//     // Cleanup timer on component unmount
//     return () => clearInterval(timerId);
//   }, [roundIndex, isBreak, company, topic, userId]);

//   const handleNextQuestion = async () => {
//     if (!answer.trim()) {
//       alert("Please enter your answer before proceeding.");
//       return;
//     }
//     setIsLoading(true);
//     try {
//       // Analyze the answer and store response in localStorage
//       const response = await axios.post(
//         `${BASE_URL}/api/interview/analyze-answer/${userId}`,
//         {
//           question: questions[currentQuestionIndex],
//           answer,
//         }
//       );
//       localStorage.setItem(
//         `analysis_round_${roundIndex}_question_${currentQuestionIndex}`,
//         JSON.stringify(response.data)
//       );

//       // Clear the answer field and move to the next question
//       setAnswer("");
//       if (currentQuestionIndex === questions.length - 1) {
//         if (roundIndex < rounds.length - 1) {
//           setIsBreak(true);
//           setRoundIndex(roundIndex + 1);
//           setCurrentQuestionIndex(0);
//           setBreakTimer(60); // Reset break timer for the next round
//         } else {
//           // Remove all relevant local storage items after the last round
//           for (let i = 0; i <= roundIndex; i++) {
//             for (let j = 0; j < questions.length; j++) {
//               localStorage.removeItem(`analysis_round_${i}_question_${j}`);
//             }
//           }
//           localStorage.removeItem("company");
//           localStorage.removeItem("topic");

//           // Navigate to the results page after clearing storage
//           navigate("/dashboard-layout/result");
//         }
//       } else {
//         setCurrentQuestionIndex(currentQuestionIndex + 1);
//       }
//     } catch (error) {
//       console.error("Error analyzing answer:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const handleCloseModal = () => {
//     setModalOpen(false);
//   };

//   const toggleMic = () => {
//     if (isMicOn) {
//       // Stop microphone functionality (you'll need to implement the logic for stopping the mic)
//       console.log("Microphone stopped.");
//     } else {
//       // Start microphone functionality (you'll need to implement the logic for starting the mic)
//       console.log("Microphone started.");
//     }
//     setIsMicOn(!isMicOn);
//   };

//   if (isLoading) {
//     return (
//       <Box
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         minHeight="80vh"
//       >
//         <CircularProgress />
//       </Box>
//     );
//   }

//   return (
//     <div
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         flexDirection: "column",
//         height: "80vh",
//         width: "100%",
//       }}
//     >
//       <Box
//         sx={{
//           // p: 4,
//           maxWidth: "800px",

//           backdropFilter: "blur(20px)", // Background blur
//           bgcolor: "transparent", // Transparent background
//           borderRadius: 2,
//         }}
//       >
//         {isBreak ? (
//           <Typography
//             variant="h6"
//             align="center"
//             sx={{ mb: 2, color: "white" }}
//           >
//             Take a break! Next round starts in: {breakTimer} seconds
//           </Typography>
//         ) : (
//           <>
//             <Typography
//               variant="h4"
//               align="center"
//               sx={{ color: "white" }}
//               gutterBottom
//             >
//               {rounds[roundIndex].roundName}
//             </Typography>
//             {questions.length > 0 && (
//               <motion.div
//                 key={currentQuestionIndex}
//                 initial={{ opacity: 0, y: 50 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 transition={{ duration: 0.5 }}
//               >
//                 <Typography variant="h6" sx={{ mb: 2, color: "white" }}>
//                   {questions[currentQuestionIndex]}
//                 </Typography>
//                 <TextField
//                   label="Your Answer"
//                   multiline
//                   rows={4}
//                   variant="outlined"
//                   fullWidth
//                   value={answer}
//                   onChange={(e) => setAnswer(e.target.value)}
//                   sx={{
//                     mb: 4,
//                     "& .MuiOutlinedInput-root": {
//                       "& fieldset": {
//                         borderColor: "white", // Border color
//                       },
//                       "&:hover fieldset": {
//                         borderColor: "white", // Border color on hover
//                       },
//                       "&.Mui-focused fieldset": {
//                         borderColor: "white", // Border color when focused
//                       },
//                     },
//                     "& .MuiInputBase-input": {
//                       bgcolor: "white", // Background color of input
//                     },
//                   }}
//                 />
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   fullWidth
//                   onClick={handleNextQuestion}
//                   disabled={isLoading}
//                   sx={{ mb: 2 }}
//                 >
//                   {currentQuestionIndex < questions.length - 1
//                     ? "Next Question"
//                     : roundIndex < rounds.length - 1
//                     ? "Next Round"
//                     : "Submit & Finish"}
//                 </Button>
//                 <Button
//                   variant="outlined"
//                   onClick={toggleMic}
//                   sx={{
//                     bgcolor: isMicOn ? "green" : "gray",
//                     color: "white",
//                     "&:hover": {
//                       bgcolor: isMicOn ? "darkgreen" : "darkgray",
//                     },
//                   }}
//                 >
//                   {isMicOn ? "Stop Mic" : "Start Mic"}
//                 </Button>
//               </motion.div>
//             )}
//           </>
//         )}
//         {/* Modal for Fullscreen Notification */}
//         {/* <Modal
//           open={modalOpen}
//           onClose={handleCloseModal}
//           closeAfterTransition
//           BackdropComponent={Backdrop}
//           BackdropProps={{ timeout: 500 }}
//         >
//           <Fade in={modalOpen}>
//             <Box
//               sx={{
//                 bgcolor: "white",
//                 borderRadius: 2,
//                 padding: 4,
//                 maxWidth: 400,
//                 margin: "auto",
//                 mt: "20%",
//               }}
//             >
//               <Typography variant="h6" gutterBottom>
//                 Important!
//               </Typography>
//               <Typography variant="body1" gutterBottom>
//                 Please do not exit fullscreen mode while taking the test.
//                 Leaving fullscreen will result in an incomplete test.
//               </Typography>
//               <Button
//                 variant="contained"
//                 onClick={handleCloseModal}
//                 color="primary"
//               >
//                 Got it!
//               </Button>
//             </Box>
//           </Fade>
//         </Modal> */}
//       </Box>
//     </div>
//   );
// };

// export default InterviewRound;

import { Mic, MicOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2"; // Import SweetAlert2
import BASE_URL from "../../../API/BaseUrl";

const MAX_ANSWER_LENGTH = 500; //Maximum characters allowed for text answers
const MIN_ANSWER_LENGTH = 50; //Minimum characters required for text answers
const InterviewRounds = () => {
  const [currentRound, setCurrentRound] = useState("aptitude");
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [textAnswer, setTextAnswer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [loadingTimer, setLoadingTimer] = useState(30);
  const [isBreak, setIsBreak] = useState(false);
  const [breakTimer, setBreakTimer] = useState(60);
  const [answers, setAnswers] = useState([]);
  const [allSubmissions, setAllSubmissions] = useState([]);
  const [questionSets, setQuestionSets] = useState(null);
  const [warningDialog, setWarningDialog] = useState(false);
  const [warnings, setWarnings] = useState(0);
  const [exitAttempted, setExitAttempted] = useState(false);
  const [isVoiceTyping, setIsVoiceTyping] = useState(false);
  const [recognition, setRecognition] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const userId = user.id || user._id;

  // Initialize speech recognition
  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      const recognitionInstance = new window.webkitSpeechRecognition();
      recognitionInstance.continuous = true;
      recognitionInstance.interimResults = true;

      recognitionInstance.onresult = (event) => {
        const transcript = Array.from(event.results)
          .map((result) => result[0].transcript)
          .join("");

        if (textAnswer.length + transcript.length <= MAX_ANSWER_LENGTH) {
          setTextAnswer((prev) => transcript);
        } else {
          stopVoiceTyping();
          Swal.fire({
            icon: "warning",
            title: "Answer Length Limit Reached",
            text: "You have reached the maximum answer length.",
          });
        }
      };

      recognitionInstance.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        stopVoiceTyping();
      };

      setRecognition(recognitionInstance);
    }
  }, []);

  const startVoiceTyping = () => {
    if (recognition) {
      recognition.start();
      setIsVoiceTyping(true);
      // Swal.fire({
      //   icon: "info",
      //   title: "Voice Typing Enabled",
      //   text: "Start speaking to input your answer.",
      //   timer: 2000,
      //   showConfirmButton: false,
      // });
    } else {
      Swal.fire({
        icon: "error",
        title: "Voice Typing Not Available",
        text: "Your browser does not support voice typing.",
      });
    }
  };

  const stopVoiceTyping = () => {
    if (recognition) {
      recognition.stop();
      setIsVoiceTyping(false);
    }
  };

  // Cleanup recognition on unmount
  useEffect(() => {
    return () => {
      if (recognition) {
        recognition.stop();
      }
    };
  }, [recognition]);

  // Check if company exists in localStorage on component mount
  useEffect(() => {
    const company = localStorage.getItem("company");
    if (!company) {
      navigate("/dashboard-layout/companyselect", {
        state: {
          message: "Please select a company before starting the interview",
        },
      });
      return;
    }

    // Store questions in sessionStorage for persistence
    const savedQuestions = sessionStorage.getItem("interviewQuestions");
    if (savedQuestions) {
      setQuestionSets(JSON.parse(savedQuestions));
    } else {
      fetchQuestions(company);
    }

    // Cleanup function to handle component unmount
    return () => {
      if (!exitAttempted) {
        sessionStorage.removeItem("interviewQuestions");
      }
    };
  }, []);

  // Loading timer effect
  useEffect(() => {
    let timer;
    if (isLoading && loadingTimer > 0) {
      timer = setInterval(() => {
        setLoadingTimer((prev) => prev - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isLoading, loadingTimer]);

  // Break timer effect
  useEffect(() => {
    let timer;
    if (isBreak && breakTimer > 0) {
      timer = setInterval(() => {
        setBreakTimer((prev) => prev - 1);
      }, 1000);
    }
    if (isBreak && breakTimer === 0) {
      handleSkipBreak();
    }
    return () => clearInterval(timer);
  }, [isBreak, breakTimer]);

  // Update questions when round changes
  useEffect(() => {
    if (questionSets) {
      setQuestions(getCurrentRoundQuestions());
    }
  }, [currentRound, questionSets]);

  // Fullscreen handling
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement && !exitAttempted) {
        setWarningDialog(true);
        setWarnings((prev) => prev + 1);
      }
    };

    const handleKeyPress = (e) => {
      // Prevent Alt+Tab, Windows key, Alt+F4
      if (
        e.altKey ||
        e.key === "Meta" ||
        e.key === "Windows" ||
        (e.altKey && e.key === "F4")
      ) {
        e.preventDefault();
        setWarningDialog(true);
        setWarnings((prev) => prev + 1);
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden && !exitAttempted) {
        setWarningDialog(true);
        setWarnings((prev) => prev + 1);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    window.addEventListener("keydown", handleKeyPress);
    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("contextmenu", (e) => e.preventDefault());

    // Initial fullscreen request
    requestFullscreen();

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      window.removeEventListener("keydown", handleKeyPress);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [exitAttempted]);

  // Handle page refresh/close
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!exitAttempted) {
        e.preventDefault();
        e.returnValue = "";
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [exitAttempted]);

  // const fetchQuestions = async (company) => {
  //   setIsLoading(true);
  //   setLoadingMessage("Generating questions...");
  //   setLoadingTimer(30);

  //   try {
  //     const response = await axios.post(
  //       `${BASE_URL}/api/interview/generate-play-questions/${userId}`,
  //       { companyName: company }
  //     );

  //     sessionStorage.setItem(
  //       "interviewQuestions",
  //       JSON.stringify(response.data.questionSets)
  //     );

  //     setQuestionSets(response.data.questionSets);
  //     localStorage.removeItem("company");
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching questions:", error);
  //     setIsLoading(false);
  //     navigate("/company-selection", {
  //       state: {
  //         message: "Error loading interview questions. Please try again.",
  //       },
  //     });
  //   }
  // };

  const fetchQuestions = async (company) => {
    setIsLoading(true);
    setLoadingMessage("Generating questions...");
    setLoadingTimer(30);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/interview/generate-play-questions/${userId}`,
        { companyName: company }
      );

      // Success notification with SweetAlert
      Swal.fire({
        icon: "success",
        title: "Questions Generated",
        text: "Your interview questions have been generated successfully!",
        showConfirmButton: true,
        timer: 3000,
      });

      // Store questions and navigate
      sessionStorage.setItem(
        "interviewQuestions",
        JSON.stringify(response.data.questionSets)
      );

      setQuestionSets(response.data.questionSets);
      localStorage.removeItem("company");
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching questions:", error);
      setIsLoading(false);

      if (error.response?.data?.isPremiumRequired) {
        // Alert for premium upgrade
        Swal.fire({
          icon: "warning",
          title: "Premium Required",
          text: error.response.data.error,
          footer: `<a href='/pricing'>Upgrade to Premium</a>`,
          showConfirmButton: true,
        });
      } else if (error.response?.status === 404) {
        Swal.fire({
          icon: "error",
          title: "User Not Found",
          text: "The user could not be found. Please log in and try again.",
          showConfirmButton: true,
        });
      } else if (error.response?.status === 403) {
        // Daily limit reached
        Swal.fire({
          icon: "info",
          title: "Daily Limit Reached",
          text: error.response.data.error,
          footer: "Try again tomorrow or consider upgrading.",
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error Loading Questions",
          text: "Failed to load questions. Please check your network and try again.",
          showConfirmButton: true,
        });
      }

      // Navigate back on error
      navigate("/dashboard-layout/companyselect", {
        state: {
          message: "Error loading interview questions. Please try again.",
        },
      });
    }
  };

  const getCurrentRoundQuestions = () => {
    if (!questionSets || !questionSets[currentRound]) return [];
    const roundQuestions = questionSets[currentRound][0].questions;
    return roundQuestions;
  };

  const handleWarningClose = () => {
    setWarningDialog(false);
    requestFullscreen();

    if (warnings >= 3) {
      setExitAttempted(true);
      handleFinalSubmission(true);
    }
  };

  const requestFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable fullscreen: ${err.message}`);
      });
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    }
  };

  const handleAnswer = (answer) => {
    if (currentRound === "aptitude") {
      setSelectedAnswer(answer);
    } else {
      if (answer.length <= MAX_ANSWER_LENGTH) {
        setTextAnswer(answer);
      }
    }
  };

  const handleSkipBreak = () => {
    setIsBreak(false);
    setBreakTimer(60);

    if (currentRound === "managerial") {
      handleFinalSubmission();
    } else {
      moveToNextRound();
    }
  };

  const moveToNextRound = () => {
    const rounds = ["aptitude", "technical1", "technical2", "managerial"];
    const currentIndex = rounds.indexOf(currentRound);

    if (currentIndex < rounds.length - 1) {
      setCurrentRound(rounds[currentIndex + 1]);
      setCurrentQuestionIndex(0);
      setAnswers([]);
      setSelectedAnswer("");
      setTextAnswer("");
    } else {
      handleFinalSubmission();
    }
  };

  const handleNextQuestion = async () => {
    const answer = currentRound === "aptitude" ? selectedAnswer : textAnswer;

    if (!answer) {
      Swal.fire({
        icon: "warning",
        title: "Answer Required",
        text: "Please provide an answer before proceeding.",
      });
      return;
    }

    if (currentRound !== "aptitude" && textAnswer.length < MIN_ANSWER_LENGTH) {
      Swal.fire({
        icon: "warning",
        title: "Answer Too Short",
        text: `Please provide at least ${MIN_ANSWER_LENGTH} characters in your answer.`,
      });
      return;
    }

    // Stop voice typing if it's active
    if (isVoiceTyping) {
      stopVoiceTyping();
    }

    setIsLoading(true);
    setLoadingMessage("Analyzing your answer...");
    setLoadingTimer(30);

    const newAnswers = [...answers, answer];
    setAnswers(newAnswers);

    if (currentQuestionIndex === questions.length - 1) {
      try {
        const submission = {
          interviewType: currentRound === "aptitude" ? "Aptitude" : "Technical",
          ...(currentRound === "aptitude" && {
            topic: "Mechanical Engineering",
            questions: questions,
          }),
          ...(currentRound !== "aptitude" && {
            companyName: `${
              currentRound.charAt(0).toUpperCase() + currentRound.slice(1)
            } Round`,
            questions: questions.map((q) =>
              typeof q === "object" ? q.question : q
            ),
          }),
          answers: newAnswers,
        };

        setAllSubmissions([...allSubmissions, submission]);
        if (currentRound === "managerial") {
          handleFinalSubmission();
        } else {
          setIsBreak(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error submitting answers:", error);
        setIsLoading(false);
      }
    } else {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer("");
      setTextAnswer("");
      setIsLoading(false);
    }
  };

  const handleFinalSubmission = async (forced = false) => {
    setIsLoading(true);
    setLoadingMessage(
      forced
        ? "Submitting interview due to multiple warning violations..."
        : "Finalizing your interview..."
    );

    try {
      await axios.post(
        `${BASE_URL}/api/interview/analyze-all-answers/${userId}`,
        {
          submissions: allSubmissions,
          forced: forced,
          warningCount: warnings,
        }
      );
      sessionStorage.removeItem("interviewQuestions");
      navigate("/dashboard-layout/result");
    } catch (error) {
      console.error("Error in final submission:", error);
      setIsLoading(false);
    }
  };

  const getCurrentQuestion = () => {
    if (!questions.length) return null;
    const question = questions[currentQuestionIndex];
    return typeof question === "object" ? question.question : question;
  };

  const getCurrentOptions = () => {
    if (!questions.length) return [];
    const question = questions[currentQuestionIndex];
    return question.options || [];
  };

  return (
    <>
      <Dialog open={warningDialog} onClose={handleWarningClose}>
        <DialogTitle sx={{ color: "error.main" }}>
          Warning: Proctoring Violation
        </DialogTitle>
        <DialogContent>
          <Typography>
            Attempting to exit fullscreen mode or switch windows is considered a
            violation of the interview protocol.
            {warnings >= 2 && (
              <strong>
                {" "}
                Warning {warnings}/3: Your interview will be automatically
                submitted after the next violation.
              </strong>
            )}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleWarningClose}
            variant="contained"
            color="primary"
          >
            Resume Interview
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="80vh"
        >
          <CircularProgress size={60} />
          <Typography variant="h6" sx={{ mt: 2, color: "white" }}>
            {loadingMessage}
          </Typography>
          <Typography variant="body1" sx={{ mt: 1, color: "white" }}>
            Please wait... {loadingTimer} seconds remaining
          </Typography>
        </Box>
      ) : isBreak ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="80vh"
        >
          <Paper
            elevation={3}
            sx={{ p: 4, maxWidth: 400, textAlign: "center" }}
          >
            <Typography variant="h5" gutterBottom>
              Take a Break!
            </Typography>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Next round starts in: {breakTimer} seconds
            </Typography>
            <Button variant="contained" onClick={handleSkipBreak}>
              Skip Break
            </Button>
          </Paper>
        </Box>
      ) : (
        <Box
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "80vh",
            padding: "20px",
            color: "white",
          }}
        >
          <Typography variant="h4" gutterBottom>
            {currentRound === "aptitude"
              ? "Aptitude Round"
              : currentRound === "technical1"
              ? "Technical Round 1"
              : currentRound === "technical2"
              ? "Technical Round 2"
              : "Managerial Round"}
          </Typography>
          <Paper
            elevation={3}
            sx={{
              p: 4,
              maxWidth: 800,
              width: "100%",
              bgcolor: "rgba(255, 255, 255, 0.9)",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Question {currentQuestionIndex + 1} of {questions.length}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {getCurrentQuestion()}
            </Typography>

            {currentRound === "aptitude" ? (
              <Box display="flex" flexDirection="column" mt={2}>
                {getCurrentOptions().map((option, index) => (
                  <Button
                    key={index}
                    variant={
                      selectedAnswer === option ? "contained" : "outlined"
                    }
                    color="primary"
                    sx={{ mb: 1 }}
                    onClick={() => handleAnswer(option)}
                  >
                    {option}
                  </Button>
                ))}
              </Box>
            ) : (
              <Box>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={textAnswer}
                  onChange={(e) => handleAnswer(e.target.value)}
                  variant="outlined"
                  placeholder="Type your answer here..."
                  error={textAnswer.length > MAX_ANSWER_LENGTH}
                  helperText={`${
                    textAnswer.length
                  }/${MAX_ANSWER_LENGTH} characters ${
                    textAnswer.length < MIN_ANSWER_LENGTH
                      ? `(Minimum ${MIN_ANSWER_LENGTH} required)`
                      : ""
                  }`}
                />
                <Box
                  sx={{ mt: 2, display: "flex", alignItems: "center", gap: 2 }}
                >
                  <Tooltip
                    title={
                      isVoiceTyping ? "Stop Voice Typing" : "Start Voice Typing"
                    }
                  >
                    <IconButton
                      color={isVoiceTyping ? "error" : "primary"}
                      onClick={
                        isVoiceTyping ? stopVoiceTyping : startVoiceTyping
                      }
                    >
                      {isVoiceTyping ? <MicOff /> : <Mic />}
                    </IconButton>
                  </Tooltip>
                  <Typography
                    variant="body2"
                    color={isVoiceTyping ? "error" : "text.secondary"}
                  >
                    {isVoiceTyping
                      ? "Voice typing active - Click to stop"
                      : "Click to enable voice typing"}
                  </Typography>
                </Box>
              </Box>
            )}

            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 3 }}
              onClick={handleNextQuestion}
            >
              {currentQuestionIndex === questions.length - 1
                ? "Submit Answers"
                : "Next Question"}
            </Button>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default InterviewRounds;