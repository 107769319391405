import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BASE_URL from "../../../API/BaseUrl";
import {
  Modal,
  Backdrop,
  Fade,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 85vh;
`;

const QuestionBox = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  overflow-y: auto;
`;

const Option = styled.div`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
    color: #000;
  }
  ${({ isSelected }) =>
    isSelected &&
    `
    background-color: #d4edda;
    color: #000;
  `}
`;

const Button = styled.button`
  margin: 10px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #cf9fff;
  color: white;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const LoadingScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  font-weight: bold;
`;

const AptitudeTest = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [testStarted, setTestStarted] = useState(false);
  const [totalTime, setTotalTime] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isBreak, setIsBreak] = useState(false);
  const [breakTimer, setBreakTimer] = useState(60);

  const user = useSelector((state) => state.auth.user);
  console.log(user);
  let userId;

  if (user.id === undefined) {
    userId = user._id;
  } else {
    userId = user.id;
  }
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post(
        `${BASE_URL}/api/interview/generate-aptitude-questions/${userId}`,
        {
          topic: "logical and reasoning and numerical",
          numQuestions: 5,
        }
      )
      .then((response) => {
        setQuestions(response.data.questions);
        setTotalTime(response.data.questions.length * 60);
        setLoading(false);
        setTestStarted(true);
      })
      /* .catch((error) => {
        console.error("Error fetching questions:", error);
        setLoading(false);
      }); */
  }, [user.id]);

  useEffect(() => {
    if (totalTime >= 0 && testStarted && !submitted) {
      const interval = setInterval(() => {
        setTotalTime((prevTime) => {
          if (prevTime === 0) {
            clearInterval(interval);
            handleSubmit();
            return 0;
          } else {
            return prevTime - 1;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [totalTime, testStarted, submitted]);

  // Keep fullscreen mode
  document.documentElement.requestFullscreen().catch((err) => {
    console.error("Error enabling full screen:", err);
  });

  useEffect(() => {
    if (isBreak) {
      const timer = setInterval(() => {
        setBreakTimer((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            navigate("/dashboard-layout/interview");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isBreak, navigate]);

  const handleOptionSelect = (option) => {
    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestionIndex] = {
      question: questions[currentQuestionIndex].question,
      userAnswer: option,
    };
    setAnswers(updatedAnswers);
  };

  const handleSubmit = async () => {
    if (submitted) return;
    setIsSubmitting(true);
    setSubmitted(true);

    const payload = {
      topic: "Logical",
      questions: questions,
      userAnswers: answers.map((answer) => answer.userAnswer),
    };

    try {
      await axios.post(
        `${BASE_URL}/api/interview/analyze-aptitude-answers/${userId}`,
        payload
      );
      setIsSubmitting(false);
      setIsBreak(true); // Trigger the break countdown
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error submitting answers:", error);
    }
  };

  const handleNext = () => {
    setCurrentQuestionIndex((prevIndex) =>
      Math.min(prevIndex + 1, questions.length - 1)
    );
  };

  const handlePrevious = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  if (loading) {
    return <LoadingScreen>Loading...</LoadingScreen>;
  }

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              bgcolor: "white",
              borderRadius: 2,
              padding: 4,
              maxWidth: 400,
              margin: "auto",
              mt: "20%",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Important!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please do not exit fullscreen mode while taking the test. Leaving
              fullscreen will result in an incomplete test.
            </Typography>
            <Button onClick={handleCloseModal}>Got it!</Button>
          </Box>
        </Fade>
      </Modal>

      <Container>
        {isBreak ? (
          <Typography
            variant="h6"
            align="center"
            sx={{ mb: 2, color: "white" }}
          >
            Take a break! Next round starts in: {breakTimer} seconds
          </Typography>
        ) : (
          <>
            <h1>Aptitude Round</h1>
            <p>Time remaining: {totalTime} seconds</p>
            <QuestionBox>
              <h3>Question {currentQuestionIndex + 1}</h3>
              <p>{currentQuestion?.question}</p>
              {currentQuestion?.options.map((option, index) => (
                <Option
                  key={index}
                  isSelected={
                    answers[currentQuestionIndex]?.userAnswer === option
                  }
                  onClick={() => handleOptionSelect(option)}
                >
                  {option}
                </Option>
              ))}
            </QuestionBox>
            <div>
              <Button
                onClick={handlePrevious}
                disabled={currentQuestionIndex === 0}
              >
                Previous
              </Button>
              {currentQuestionIndex < questions.length - 1 ? (
                <Button onClick={handleNext}>Next</Button>
              ) : (
                <Button
                  onClick={handleSubmit}
                  disabled={answers.length !== questions.length}
                >
                  Submit
                </Button>
              )}
            </div>
            {isSubmitting && <CircularProgress sx={{ mt: 2 }} />}
          </>
        )}
      </Container>
    </>
  );
};

export default AptitudeTest;
