import React from "react";
import { FaRocket, FaLightbulb, FaHandsHelping, FaStar } from "react-icons/fa";
import { motion } from "framer-motion";
import GajendraImg from "../Images/Profile_Pictures/GajendraSir.jpg";
import GaneshImg from "../Images/Profile_Pictures/Ganesh.jpg";
import NirajImg from "../Images/Profile_Pictures/Niraj.jpg";
import AvinashImg from "../Images/Profile_Pictures/Avinash.jpg";
import AumImg from "../Images/Profile_Pictures/Aum.jpg";
import ShriramImage from "../Images/Profile_Pictures/Shriram.jpg";
import Footer from "../components/Footer/Footer";
import { LinkedIn, Instagram } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const About = () => {
  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };
  const navigate = useNavigate();
  const redirectiontocontactpage = () => {
    navigate("/contact");
  };
  const teamMembers = [
    {
      name: "Gajendra Deshpande",
      img: GajendraImg,
      designation: "Founder & CEO",
      linkedin: "https://www.linkedin.com/in/gajendradeshpande/",
      instagram: "https://www.instagram.com/gajendradeshpande/",
    },
    {
      name: "Niraj Vernekar",
      img: NirajImg,
      designation: "Project Manager",
      linkedin: "https://www.linkedin.com/in/niraj-vernekar-691875196/",
      instagram: "https://www.instagram.com/nirajvernekar02/",
    },
    {
      name: "Ganesh Kugaji",
      img: GaneshImg,
      designation: "MERN Stack Developer",
      linkedin: "https://www.linkedin.com/in/ganesh-kugaji05/",
      instagram: "https://www.instagram.com/kugajiganesh/",
    },
    {
      name: "Avinash Pauskar",
      img: AvinashImg,
      designation: "Frontend Developer",
      linkedin: "https://www.linkedin.com/in/avinash-pauskar/",
      instagram: "https://www.instagram.com/avinashh.5/",
    },
    {
      name: "Aum Pauskar",
      img: AumImg,
      designation: "AI/ML Developer",
      linkedin: "https://www.linkedin.com/in/aum-pauskar-a5229b217/",
      instagram: "https://www.instagram.com/aumpauskar79/",
    },
    {
      name: "Shriram Naik",
      img: ShriramImage,
      designation: "AI/ML Architect",
      linkedin: "https://www.linkedin.com/in/shriram-naik-01b4641a9/",
      instagram: "https://www.instagram.com/shriram_naik/",
    },
  ];

  return (
    <div
      className="bg-gray-100 min-h-screen mt-6"
      sx={{ display: "flex", alignItems: "center" }}
    >
      <header className=" text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-blue-600 text-white py-16">
        <div className="container mx-auto px-4">
          <motion.h1
            className="text-4xl md:text-5xl font-bold mb-4"
            {...fadeIn}
          >
            About HireHack
          </motion.h1>
          <motion.p className="text-xl" {...fadeIn} transition={{ delay: 0.2 }}>
            Empowering careers through innovative AI-powered interview
            preparation
          </motion.p>
        </div>
      </header>

      <main className="container mx-auto px-4 py-16">
        <section className="mb-16">
          <motion.h2 className="text-3xl font-bold mb-6" {...fadeIn}>
            Our Vision
          </motion.h2>
          <motion.p
            className="text-lg mb-4"
            {...fadeIn}
            transition={{ delay: 0.2 }}
          >
            We envision a future where job candidates approach interviews with
            confidence, armed with the right tools for success. At HireHack,
            we're continuously innovating to improve interview preparation on a
            global scale.
          </motion.p>
          <section className="mb-16">
            <motion.h2 className="text-3xl font-bold mb-6" {...fadeIn}>
              Our Mission
            </motion.h2>
            <motion.p
              className="text-lg mb-4"
              {...fadeIn}
              transition={{ delay: 0.2 }}
            >
              We're on a mission to democratize interview preparation by
              providing advanced tools accessible to everyone. We believe every
              candidate deserves a fair chance to succeed, regardless of their
              background or experience level.
            </motion.p>
          </section>
        </section>
        <section className="mb-16">
          <motion.h2 className="text-3xl font-bold mb-6" {...fadeIn}>
            Who We Are
          </motion.h2>
          <motion.p className="text-lg" {...fadeIn} transition={{ delay: 0.2 }}>
            At HireHack, we're passionate about helping individuals excel in
            their careers. We've harnessed the power of innovative AI tools to
            bridge the gap between candidates and recruiters, revolutionizing
            the way people prepare for interviews.
          </motion.p>
        </section>
        <section className="mb-16">
          <motion.h2 className="text-3xl font-bold mb-6" {...fadeIn}>
            Why We Created HireHack
          </motion.h2>
          <motion.p
            className="text-lg mb-4"
            {...fadeIn}
            transition={{ delay: 0.2 }}
          >
            We recognized the need for smarter interview preparation tools in
            today's competitive job market. By combining the expertise of
            engineers, career coaches, and AI specialists, we've created a
            platform that empowers job seekers with cutting-edge technology.
          </motion.p>
        </section>

        <section className="mb-16">
          <motion.h2 className="text-3xl font-bold mb-6" {...fadeIn}>
            Our Values
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              className="bg-white p-6 rounded-lg shadow-md"
              {...fadeIn}
              transition={{ delay: 0.2 }}
            >
              <FaRocket className="text-4xl text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Innovation</h3>
              <p>
                We're always pushing the boundaries of what's possible in
                interview preparation technology.
              </p>
            </motion.div>
            <motion.div
              className="bg-white p-6 rounded-lg shadow-md"
              {...fadeIn}
              transition={{ delay: 0.3 }}
            >
              <FaHandsHelping className="text-4xl text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Accessibility</h3>
              <p>
                We're committed to making our tools available to everyone,
                regardless of their circumstances.
              </p>
            </motion.div>
            <motion.div
              className="bg-white p-6 rounded-lg shadow-md"
              {...fadeIn}
              transition={{ delay: 0.4 }}
            >
              <FaLightbulb className="text-4xl text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Empowerment</h3>
              <p>
                We believe in giving candidates the confidence and skills they
                need to succeed in their careers.
              </p>
            </motion.div>
            <motion.div
              className="bg-white p-6 rounded-lg shadow-md"
              {...fadeIn}
              transition={{ delay: 0.5 }}
            >
              <FaStar className="text-4xl text-blue-600 mb-4" />
              <h3 className="text-xl font-semibold mb-2">Excellence</h3>
              <p>
                We strive for excellence in everything we do, from our
                technology to our customer support.
              </p>
            </motion.div>
          </div>
        </section>

        <section className="mb-16">
          <motion.h2 className="text-3xl font-bold mb-6" {...fadeIn}>
            Meet the Team
          </motion.h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {teamMembers.map((person, index) => (
              <motion.div
                key={person.name}
                className="bg-white p-6 rounded-lg shadow-md text-center"
                {...fadeIn}
                transition={{ delay: 0.2 + index * 0.1 }}
              >
                <img
                  src={person.img}
                  alt={person.name}
                  className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"
                />
                <h3 className="text-xl font-semibold mb-2">{person.name}</h3>
                <p className="text-gray-600 mb-4">{person.designation}</p>
                <div className="flex justify-center space-x-4">
                  <a
                    href={person.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <LinkedIn size={24} />
                  </a>
                  <a
                    href={person.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-pink-600 hover:text-pink-800"
                  >
                    <Instagram size={24} />
                  </a>
                </div>
              </motion.div>
            ))}
          </div>
        </section>
        <section>
          <motion.h2 className="text-3xl font-bold mb-6" {...fadeIn}>
            Join Us on Our Journey
          </motion.h2>
          <motion.p
            className="text-lg mb-8"
            {...fadeIn}
            transition={{ delay: 0.2 }}
          >
            We invite job seekers, recruiters, and educational institutions to
            join us in revolutionizing interview preparation. Together, we can
            create a world where everyone has the opportunity to showcase their
            true potential.
          </motion.p>
          <motion.button
            className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-3.5 w-64 font-bold btn-alt"
            style={{
              borderRadius: "32px",
            }}
            initial={{ backgroundPosition: "200% center" }}
            animate={{ backgroundPosition: "0% center" }}
            transition={{ duration: 0.2, ease: "linear" }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={redirectiontocontactpage}
          >
            Get Involved
          </motion.button>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default About;